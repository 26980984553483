import React, {useEffect, useState} from 'react';
import {Button, FloatButton, Image, Input, Layout, message, Result, Space, Steps, Typography} from 'antd';
import './App.css';
import {ArrowLeftOutlined, ArrowRightOutlined, CopyOutlined, FacebookOutlined, SmileOutlined,} from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import copy from "copy-to-clipboard";
import * as UglifyJS from "terser";
import showButtonAnimation from './animations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons'; // Приклад іконки з набору solid
import { faApple, faInstagram, faYoutube, faTelegram, faFacebook,faViber } from '@fortawesome/free-brands-svg-icons'; // Приклад іконки з набору brands

const {Title, Paragraph} = Typography;
const {Header, Footer, Sider, Content} = Layout;


function App() {
    const [current, setCurrent] = useState(0);
    const [facebookMainPixel, setFacebookMainPixel] = useState('442972027281036');
    const [facebookMainCode, setFacebookMainCode] = useState(null);
    const [originalCode, setOriginalCode] = useState(null);
    const [isMinified, setIsMinified] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    useEffect(() => {
        const initFacebookCode = String.raw`(function (windowObj, documentObj, scriptTag, src) {
    if (windowObj.fbq) return;
    let fbqFunction = windowObj.fbq = function () {
        fbqFunction.callMethod ?
            fbqFunction.callMethod.apply(fbqFunction, arguments) : fbqFunction.queue.push(arguments)
    };
    if (!windowObj._fbq) windowObj._fbq = fbqFunction;
    fbqFunction.push = fbqFunction;
    fbqFunction.loaded = true;
    fbqFunction.version = '2.0';
    fbqFunction.queue = [];
    let script = documentObj.createElement(scriptTag);
    script.async = true;
    script.src = src;
    let firstScript = documentObj.getElementsByTagName(scriptTag)[0];
    firstScript.parentNode.insertBefore(script, firstScript);
}(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js"));
const fb_multipixel_init = () => {
    const set_cookie = (name, value, options = {}) => {
        options = {path: '/', ...options, SameSite: 'None', Secure: true}
        if (options.expires instanceof Date)
            options.expires = options.expires.toUTCString();
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true)
                updatedCookie += "=" + optionValue;
        }
        document.cookie = updatedCookie;
    }
    const get_cookie = (name) => {
        let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\\.$?*|{}\\(\\)\\[\\]\\\\\\/\\+^])/g, '\\\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const create_script_pixel = (pixel) => {
        let script = document.createElement('script');
        script.textContent += "fb" + "q('init', '" + pixel + "')" + "\\n"
        script.textContent += "fb" + "q('trackSingle', 'PageView', '" + pixel + "', {referrer: document.referrer});"
        document.body.appendChild(script);
    }
    const create_noscript_pixel = (pixel) => {
        var noscript = document.createElement('noscript');
        var img = document.createElement('img');
        img.height = 1;
        img.width = 1;
        img.style.display = 'none';
        img.src = 'https://www.facebook.com/tr?id=' + pixel + '&ev=PageView&noscript=1&dpo=';
        noscript.appendChild(img);
        document.body.appendChild(noscript);
    }
    const check_is_correct_pixel = (pixel) => {
        return /^[0-9]{15,16}$/.test(pixel);
    }
    const get_fb_multipixels_from_cookie = () => {
        let fb_multipixels = get_cookie('fbmpixels');
        if (fb_multipixels)
            try {
                let parsed = JSON.parse(fb_multipixels);
                if (Array.isArray(parsed))
                    return parsed;
                else return [];
            } catch (e) {
                return [];
            }
        return [];
    }
    let searchParams = new URLSearchParams(window.location.search);
    const pixel_names = [
        'sub6',
        'sub7',
        'sub8',
    ]
    let find_pixels = []
    pixel_names.map((pixel_name) => {
        let pixel = searchParams.get(pixel_name)
        if (pixel && check_is_correct_pixel(pixel)) {
            find_pixels.push(pixel)
            set_cookie('fbmpixels', JSON.stringify(find_pixels), {path: '/', secure: true})
        }
    })
    create_script_pixel('${facebookMainPixel}')
    const fb_multipixels = get_fb_multipixels_from_cookie()
    fb_multipixels.map((pixel) => {
        create_script_pixel(pixel)
    })
    fbq('track', 'PageView', {referrer: document.referrer});
}
document.addEventListener("DOMContentLoaded", fb_multipixel_init)
`;

        setFacebookMainCode(initFacebookCode);
    }, [facebookMainPixel]); // Виконується один раз при монтажі компонента

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    console.log('App');
    const restoreOriginalCode = () => {
        setFacebookMainCode(originalCode);
    }
    const toggleCode = () => {
        if (isMinified) {
            // Якщо код вже мініфікований, відновлюємо оригінальний код
            setFacebookMainCode(originalCode);
            setIsMinified(false);
        } else {
            // Якщо код не мініфікований, зберігаємо оригінальний код та мініфікуємо його
            setOriginalCode(facebookMainCode);
            minify(facebookMainCode).then(result => {
                if (result.error) {
                    console.error('Error minifying code:', result.error);
                } else {
                    setFacebookMainCode(result.code);
                    setIsMinified(true);
                }
            });
        }
    }

    const minify = async (code) => {
        return await UglifyJS.minify(code);
    }
    const copy_handler = (e) => {
        copy(facebookMainCode.toString(), {
            debug: true, message: 'Press #{key} to copy',
        });
        message.success('Код скопійовано в буфер обміну')
    }
    const steps = [{
        title: 'Pixel ID', content: <>
            <Space direction={'vertical'}>
                <div className={'steps_subtitle'}>
                    Для початку розберемось з піксель айді<br/>
                    Вставте Pixel ID у поле та продовжимо
                </div>
                <div>
                    <Input size="large"
                           className={'facebookPixelIdInput'}
                           placeholder="Pixel ID"
                           value={facebookMainPixel}
                           onChange={(e) => setFacebookMainPixel(e.target.value)}
                           prefix={<FacebookOutlined style={{
                               color: '#1890ff',
                           }}/>}/>
                </div>
            </Space>
        </>,
    }, {
        title: 'Генерація коду', content: <>
            <Space>
                <div className={'steps_subtitle'}>
                    Далі згенерований код треба вставити на сайт в кінець тегу body<br/>
                </div>
                <Space>
                    <Button icon={<SmileOutlined/>} onClick={toggleCode}>
                        {isMinified ? "Відновити" : "Мініфікувати"}
                    </Button>
                    <Button icon={<CopyOutlined/>} type={'primary'} onClick={copy_handler}>Копіювати</Button>
                </Space>
            </Space>
            <div className={'facebookCodeBlock'}>
                <SyntaxHighlighter
                    language="javascript"
                    style={androidstudio}
                    showLineNumbers={true}>
                    {facebookMainCode}
                </SyntaxHighlighter>
            </div>
        </>,
    }, {
        title: 'Додаткова інформація', content: <>
            <Space>
                <div>
                    <div className={'steps_subtitle'}>
                        Тепер у нас є піксель, для того щоб перетворити його на мульті-піксель треба додати до
                        посилання на сайт параметри:<br/>
                        <b>{'?sub6={PixelID}&sub7={PixelID}&sub8={PixelID}'}</b>
                    </div>
                    <br/>
                    <div className={'steps_subtitle'}>
                        Далі за допомогою помічника пікселів перевірте чи все працює як треба, тобто на кожен
                        піксель по події <b>PageView</b>
                        <br/>
                        <br/>
                        <Image
                            alt={'fb_pixel_helper'}
                            height={200}
                            src="https://resource.hillary.ua/img/fbmpixel/fb_pixel_tutorial_1.jpg"
                        />
                    </div>
                    <br/>
                    <a href={'https://developers.facebook.com/docs/meta-pixel/support/pixel-helper/?locale=ru_RU'}
                       target={'_blank'}>
                        Помічник з пікселів Meta
                    </a>
                    <br/>
                    <br/>
                    <div>
                        Помічник пікселів — це розширення для браузера Chrome, яке працює у фоновому режимі та
                        автоматично перевіряє сайти на наявність коду пікселя Meta. Коли це розширення встановлено,
                        у верхньому правому куті вікна браузера поруч з адресним рядком відображається піктограма
                        помічника за пікселями: <img
                        src={'https://resource.hillary.ua/img/icon/facebookpixelhelper.jpg'} height={'16px'}/> .
                        Якщо на сайті встановлено піксель Meta, піктограма помічника пікселів стає синім. Крім того,
                        з'являється ще один невеликий значок із зазначенням кількості виявлених на сторінці
                        пікселів. Якщо піктограма не стає синьою, це означає, що на сторінці немає пікселів Meta.
                        Якщо натиснути піктограму помічника по пікселям, відкриється невелике спливаюче вікно з
                        інформацією, яка дозволяє перевірити працездатність пікселя, усунути неполадки та покращити
                        його роботу.
                    </div>
                </div>
            </Space>
        </>,
    },];
    const items = steps.map((item) => ({
        key: item.title, title: item.title,
    }))
    const renderStepsButton = () => {
        if (current === 0) {
            return (<Button icon={<ArrowRightOutlined/>} size={'small'} className={'steps_button'} type="primary"
                            onClick={() => next()}>Далі</Button>);
        }

        if (current > 0 && current < steps.length - 1) {
            return (<>
                <Button icon={<ArrowLeftOutlined/>} size={'small'} className={'steps_button'}
                        style={{margin: '0 8px'}} onClick={() => prev()}>Назад</Button>
                <Button icon={<ArrowRightOutlined/>} size={'small'} className={'steps_button'} type="primary"
                        onClick={() => next()}>Далі</Button>
            </>);
        }

        if (current === steps.length - 1) {
            return <>
                <Button icon={<ArrowLeftOutlined/>} size={'small'} className={'steps_button'} style={{margin: '0 8px'}}
                        onClick={() => prev()}>Назад</Button>
                <Button size={'small'} className={'steps_button'} onClick={(e) => {
                    message.success('Всьо, інсталяція завершена, ви прекрасні!')
                    setIsFinished(true)
                    showButtonAnimation(e)
                }}>🥳 Готово</Button>
            </>;
        }
    }

    return <>
        <Layout>
            <FloatButton
                icon={<img src={'https://resource.hillary.ua/img/logo/telegram_logo.svg'}/>}
                onClick={() => {
                    console.log('click')
                    //open in new tab
                    window.open('https://t.me/hillaryshopbot', '_blank')
                }}/>
            <Header>
                <img src="https://resource.hillary.ua/img/logo/logo_mine_white.png" alt="Hillary"/>
            </Header>
            <Content>
                <h1>👩🏼‍💻 Інструкція по встановленню мульті пікселя {renderStepsButton()}</h1>
                <Steps current={current} items={items}/>
                <div className={'steps_content'}>
                    {!isFinished ?
                        steps[current].content: <Result
                            status="success"
                            title="Всьо, істаляція завершена, ви прекрасні!"
                            extra={<Button type="primary">Купіть шось</Button>}
                        />}
                </div>
            </Content>
            <Footer>
                <Space class="socials_footer" direction={'horizontal'}>
                    <FontAwesomeIcon icon={faInstagram} />
                    <FontAwesomeIcon icon={faTelegram} />
                    <FontAwesomeIcon icon={faFacebook} />
                    <FontAwesomeIcon icon={faYoutube} />
                    <FontAwesomeIcon icon={faViber} />
                </Space>
                <div>Україна, Пирогівці, Хмельницька область, 31343 вул. Гайдара</div>
                <div>ТОВ "Аркадія"</div>
                <a href={'https://hillary.ua/'} target={'_blank'} style={{color: 'black'}}><b>❤️HiLLARY</b></a>
                <a href={'https://t.me/xxxnonsense'} target={'_blank'} style={{fontSize: '11px'}}>@xxxnonsense</a>
            </Footer>
        </Layout>
    </>
}

export default App;