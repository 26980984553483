const particleConfigurations = [
    {
        symbols: ['🎁', '🎉', '🎊'],
        type: 'symbol',
        quantity: 16,
        style: {
            fontSize: '10px'
        },
        size: {
            min: 8,
            max: 18
        }
    },
    {
        symbols: [
            'https://resource.hillary.ua/img/icon/black_logo.png',
            'https://resource.hillary.ua/img/icon/black_logo.png',
            'https://resource.hillary.ua/img/icon/black_logo.png',
            'https://resource.hillary.ua/img/icon/ua_logo.png',
            'https://resource.hillary.ua/img/icon/trizub.svg',
            'https://resource.hillary.ua/img/icon/ua_flag.svg',
            'https://resource.hillary.ua/img/icon/ua_map.png',
        ],
        type: 'image',
        quantity: 36,
        size: {
            min: 20,
            max: 26
        },
        style: {
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        }
    },
    {
        type: 'shadow',
        quantity: 36,
        colors: [
            'rgb(0,158,255)',
            'rgb(255,255,0)',
        ],
        style: {
            transition: 'box-shadow 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out',
            borderRadius: '50%'
        },
        size: {
            min: 4,
            max: 8
        }
    }
];

// Решта коду залишається незмінним

const createParticle = (x, y, config) => {
    const particle = document.createElement('div');
    particle.classList.add('particle');
    document.body.appendChild(particle);

    const sizeConfig = config.size;
    let width, height;

    if (config.type === 'symbol' || config.type === 'shadow') {
        width = height = Math.random() * (sizeConfig.max - sizeConfig.min) + sizeConfig.min;
    }

    particle.style.width = `${width}px`;
    particle.style.height = `${height}px`;
    particle.style.position = 'fixed';
    particle.style.top = `${y}px`;
    particle.style.left = `${x}px`;

    const style = particleConfigurations.find(c => c.type === config.type).style;
    Object.assign(particle.style, style);

    if (config.type === 'symbol') {
        const symbols = config.symbols;
        const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
        particle.innerHTML = randomSymbol;
        particle.style.fontSize = `${Math.random() * (config.size.max - config.size.min) + config.size.min}px`;
    } else if (config.type === 'image') {
        const images = config.symbols;
        const randomImage = images[Math.floor(Math.random() * images.length)];
        const shadowSize = `${Math.random() * (config.size.max - config.size.min) + config.size.min}`;
        particle.style.backgroundImage = `url(${randomImage})`;
        particle.style.width = `${shadowSize}px`;
        particle.style.height = `${shadowSize}px`;
        particle.style.maxWidth = `${shadowSize}px`;
        particle.style.maxHeight = `${shadowSize}px`;
    } else if (config.type === 'shadow') {
        const colors = config.colors;
        const color = colors[Math.floor(Math.random() * colors.length)];
        const shadowSize = `${Math.random() * (config.size.max - config.size.min) + config.size.min}`;
        particle.style.boxShadow = `0 0 ${shadowSize}px ${color}`;
        particle.style.background = color;
        particle.style.width = `${shadowSize}px`;
        particle.style.height = `${shadowSize}px`;
        particle.style.maxWidth = `${shadowSize}px`;
        particle.style.maxHeight = `${shadowSize}px`;
    }

    const destinationX = (Math.random() - 0.5) * 900;
    const destinationY = (Math.random() - 0.5) * 600;
    const rotation = Math.random() * 520;
    const delay = Math.random() * 200;

    const animation = particle.animate([
        {
            transform: `translate(0px, 0px) rotate(0deg)`,
            opacity: 0.8
        },
        {
            transform: `translate(${destinationX}px, ${destinationY}px) rotate(${rotation}deg)`,
            opacity: 0
        }
    ], {
        duration: Math.random() * 1000 + 5000,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        delay: delay
    });

    animation.onfinish = (e) => {
        e.srcElement.effect.target.remove();
    };
};

const showButtonAnimation = (e) => {
    const x = e.clientX || e.pageX;
    const y = e.clientY || e.pageY;
    particleConfigurations.forEach(config => {
        for (let i = 0; i < config.quantity; i++) {
            createParticle(x, y, config);
        }
    });
};

export default showButtonAnimation;
